// hooks
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getEvent } from "../../actions/events";

// m-ui components
import { Button, Container, Grid } from "@material-ui/core";
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// components
import TopNav from "../../components/topNav/TopNav";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";

// event page components
import Event from "./Event";
import Pourquoi from "./Pourquoi";
import Mots from "./Mots";

// styles
import useStyles from "./styles";
import LeftNav from "../../components/leftNav/LeftNav";
import Header from "../../components/header/Header";

const EventPage = () => {
  const dispatch = useDispatch();
  const { event } = useSelector((state) => state.events);

  // state to select witch component to show
  const [componentToShow, setComponentToShow] = useState({
    component: "event",
  });

  // init the classes to use for styles
  const classes = useStyles();

  // getting the id from the link
  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getEvent(id));
  }, [id, dispatch]);

  return (
    <Grid className={classes.mainPage}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.main}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Grid className={classes.rightEvent}>
              <Grid className={classes.btns}>
                <Button
                  className={
                    componentToShow.component === "event"
                      ? `${classes.btnSelected}`
                      : `${classes.btn}`
                  }
                  variant="outlined"
                  size="medium"
                  onClick={() => setComponentToShow({ component: "event" })}
                >
                  Event
                </Button>
                <Button
                  className={
                    componentToShow.component === "pourquoi"
                      ? `${classes.btnSelected}`
                      : `${classes.btn}`
                  }
                  variant="outlined"
                  size="medium"
                  onClick={() => setComponentToShow({ component: "pourquoi" })}
                >
                  Pourquoi assister
                </Button>
                <Button
                  className={
                    componentToShow.component === "mots"
                      ? `${classes.btnSelected}`
                      : `${classes.btn}`
                  }
                  variant="outlined"
                  size="medium"
                  onClick={() => setComponentToShow({ component: "mots" })}
                >
                  Mots des presidents
                </Button>
              </Grid>
              {/* changed components under the above btns conditions */}
              {(() => {
                switch (componentToShow.component) {
                  case "pourquoi":
                    return <Pourquoi why={event.POURQUOI} />;
                  case "mots":
                    return <Mots id={id} />;
                  default:
                    return <Event id={id} />;
                }
              })()}
            </Grid>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default EventPage;
