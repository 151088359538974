// m-ui components
import { Paper, Grid, Typography } from "@material-ui/core";

// components
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// styles
import useStyles from "./styles";

const Pourquoi = ({ why }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperWord} elevation={6}>
      {!why ? (
        <LoadingCard type="line" />
      ) : (
        <>
          <Grid className={classes.why}>
            <Typography variant="body2">{why}</Typography>
          </Grid>
        </>
      )}
    </Paper>
  );
};

export default Pourquoi;
