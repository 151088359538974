import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    main: {
        backgroundColor: '#00276A',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    right: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    left: {
        width: '80px',
    },
    mainPage: {
        display: 'flex',
        justifyContent: 'space-between',
        overflowY: 'scroll',
    },
    rightMain: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    mainRight: {
        borderRadius: '15px',
        margin: '20px 0px',
        width: '70%',
        padding: '20px',
    },
    title: {
        margin: '20px 10px',
        fontSize: '28px'
    },
    flex: {
        display: 'flex',
        width: '100%',
        marginBottom: '20px'
    },
    name: {
        color: '#FCB026',
        width: '20% !important',
    },
    nameTitle: {
        fontWeight: '500',
        width: '70% !important',
    },
    pdf: {
        width: '100%',
        height: '100vh',
        borderRadius: '15px',
        marginBottom: '30px'
    },
    iframe: {
        width: '100%',
        height: '100vh',
        borderRadius: '15px'
    },
    [theme.breakpoints.down('md')]: {

    },

}))