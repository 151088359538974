// hooks
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// m-ui components
import {
  Grid,
  Card,
  CardMedia,
  CardActionArea,
  Paper,
  Typography,
} from "@material-ui/core";

// components
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// styles
import "./currentEvent.css";

// image path
import { IMAGE_PATH } from "../../constants/actionTypes";

const CurrentEvent = ({ classPath, item }) => {
  const navigate = useNavigate();
  // seting the count down date as the current event's date
  const [countdownDate, setCountdownDate] = useState();

  // init the state
  const [state, setState] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // setting the time of our counter
  const setNewTime = () => {
    if (countdownDate) {
      // the now date
      const currentTime = new Date().getTime();

      // the distance between the event's date and now
      const distanceToDate = countdownDate - currentTime;

      let days = Math.floor(distanceToDate / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distanceToDate % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor(
        (distanceToDate % (1000 * 60 * 60)) / (1000 * 60)
      );
      let seconds = Math.floor((distanceToDate % (1000 * 60)) / 1000);

      // making the number containes 2 digits
      const numbersToAddZeroTo = [1, 2, 3, 4, 5, 6, 7, 8, 9];

      days = `${days}`;
      if (numbersToAddZeroTo.includes(hours)) {
        hours = `0${hours}`;
      } else if (numbersToAddZeroTo.includes(minutes)) {
        minutes = `0${minutes}`;
      } else if (numbersToAddZeroTo.includes(seconds)) {
        seconds = `0${seconds}`;
      }

      // seting the state with the new state
      setState({ days: days, hours: hours, minutes, seconds });
    }
  };

  // checking to make the counter
  useEffect(() => {
    setCountdownDate(new Date(item.DATE).getTime());
    setInterval(() => setNewTime(), 1000);
  }, [item, countdownDate]);

  return (
    <>
      {item ? (
        <Grid className={`${classPath === "main" ? "main" : "event"}`}>
          {/* the card  to containe the cuurent event */}
          <Card sx={{ maxWidth: 345 }} className="card">
            <CardActionArea>
              <CardMedia
                component="img"
                height="200"
                image={IMAGE_PATH + item.IMG}
                alt={item.IMG}
                className="img"
                onClick={() => navigate(`/event/${item.ID_EVENT}`)}
              />
            </CardActionArea>
            <Typography variant="body1" className="currentImageTitle">
              {item.LIEU}
            </Typography>
          </Card>

          {/* paper to containe the info about the current event */}
          <Paper className="main-paper" elevation={3}>
            {/* event timing */}
            <div className="main-paper-top">
              <div className="main-left">
                <div className="left-top">
                  <span className="d">{state.days < 0 ? "0" : state.days}</span>
                  <span className="h">
                    {state.hours < 0 ? "0" : state.hours}
                  </span>
                  <span className="m">
                    {state.minutes < 0 ? "0" : state.minutes}
                  </span>
                  <span className="s">
                    {state.seconds < 0 ? "0" : state.seconds}
                  </span>
                </div>
                <div className="left-bottom">
                  <span>D</span>
                  <span>H</span>
                  <span>M</span>
                  <span>S</span>
                </div>
              </div>
              <div className="main-center">
                <Typography variant="h5">{item.PERIODE}</Typography>
              </div>
            </div>
            {/* event content */}
            <div className="main-paper-bottom">
              <div className="text">
                <p>{item.DESCRIPTION.toLowerCase()}</p>
              </div>
            </div>
          </Paper>
        </Grid>
      ) : (
        <LoadingCard type="card" />
      )}
    </>
  );
};

export default CurrentEvent;
