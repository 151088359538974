// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import {
  Container,
  Typography,
  Grid,
  Paper,
  Card,
  CardActionArea,
  CardMedia,
} from "@material-ui/core";

// components
import LeftNav from "../../components/leftNav/LeftNav";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";

// pdf constant
import { IMAGE_PATH, PDF_PATH } from "../../constants/actionTypes";

// actions
import { getEvent } from "../../actions/events";

// styles
import useStyles from "./styles";

const Program = () => {
  const dispatch = useDispatch();
  const { event } = useSelector((state) => state.events);

  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getEvent(id));
  }, [id, dispatch]);

  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Grid className={classes.flex}>
                <Typography variant="h6" className={classes.name}>
                  Program
                </Typography>
                <Card
                  className={classes.card}
                  style={{
                    width: "70%",
                    height: "300px",
                    borderRadius: "15px",
                  }}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="300px"
                      image={IMAGE_PATH + event.PROGRAMME}
                      alt={event?.TITRE}
                    />
                  </CardActionArea>
                </Card>
              </Grid>
            </Paper>
          </Grid>
          <Grid className={classes.pdf}>
            <iframe
              src={PDF_PATH + event.PROGRAMME_PDF}
              className={classes.iframe}
              title={event.TITRE}
            />
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Program;
