import { START_LOADING, END_LOADING, GET_NEXT_EVENTS, GET_CURRENT_EVENTS, GET_EVENTS_BY_ID, GET_ARCHIVE_EVENTS, GET_ALL_EPOSTERS_BY_EVENT, GET_APROPOS, GET_HOTELS, GET_CONFIRENCIER, GET_EVENTS_WORDS, GET_EVENT_ALL_EPOSTERS, GET_EPOSTER_BY_ID, EPOSTER_ID_LOCALSTORAGE, GET_EVENT_SPONSORS_GOLD, GET_EVENT_SPONSORS_BRONZE, GET_GALERIES, GET_COMITE_ORGANISATION, GET_COMITE_SCIENTIFIQUE, GET_EVENT_CONTACTS, CREATE_EVENT } from "../constants/actionTypes";

const eventsReducers = (state = { isLoading: true, events: [], eventWords: [], next: [], archive: [], hotels: [], confirenciers: [], eventAllEposters: [], epostersByEvent: [], eposterId: [], sponsorsGold: [], sponsorsBronze: [], galories: [], comiteOrganisation: [], comitesCIENTIFIQUE: [], contacts: [] }, action) => {
    switch(action.type) {
        case START_LOADING:
            return { ...state, isLoading: true };
        case END_LOADING:
            return { ...state, isLoading: false };
        case CREATE_EVENT:
            return { ...state, next: [ ...state.next, action.payload ] };
        case GET_CURRENT_EVENTS:
            return { ...state, current: action.payload.current };
        case GET_NEXT_EVENTS:
            return { ...state, next: action.payload };
        case GET_ARCHIVE_EVENTS:
            return { ...state, archive: action.payload };
        case GET_EVENTS_BY_ID:
            return { ...state, event: action.payload.event };
        case GET_EVENTS_WORDS:
            return { ...state, eventWords: action.payload };
        case GET_EVENT_ALL_EPOSTERS:
            return { ...state, eventAllEposters: action.payload };
        case GET_ALL_EPOSTERS_BY_EVENT:
            return { ...state, epostersByEvent: action.payload };
        case GET_EPOSTER_BY_ID:
            return { ...state, eposter: action.payload.eposter };
        case GET_APROPOS:
            return { ...state, apropos: action.payload.apropos };
        case GET_HOTELS:
            return { ...state, hotels: action.payload };
        case GET_EVENT_CONTACTS:
            return { ...state, contacts: action.payload };
        case GET_CONFIRENCIER:
            return { ...state, confirenciers: action.payload };
        case EPOSTER_ID_LOCALSTORAGE:
            return { ...state, eposterId: action.payload };
        case GET_EVENT_SPONSORS_GOLD:
            return { ...state, sponsorsGold: action.payload };
        case GET_EVENT_SPONSORS_BRONZE:
            return { ...state, sponsorsBronze: action.payload };
        case GET_GALERIES:
            return { ...state, galories: action.payload };
        case GET_COMITE_SCIENTIFIQUE:
            return { ...state, comiteScientifique: action.payload };
        case GET_COMITE_ORGANISATION:
            return { ...state, comiteOrganisation: action.payload };
        default:
            return state;
    }
}

export default eventsReducers;