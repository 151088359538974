import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

    main: {
        backgroundColor: '#00276A',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    right: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    left: {
        width: '80px'
    },
    slideTitle: {
        color: '#FCB026',
        textShadow: '1px 1px 1px white',
        fontWeight: '400'
    },
    slide: {
        height: '250px',
        display: 'flex',
        justifyContent: 'left',
        margin: '20px 0px',
        padding: '20px',
        overflowX: 'scroll',
        borderRadius: '10px'
    },

    [theme.breakpoints.down('md')]: {

    },

}))