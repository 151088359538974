// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getEvent, getAllContacts } from "../../actions/events";

// components
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import LeftNav from "../../components/leftNav/LeftNav";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";

// m-ui components
import { Container, Grid, Typography, Paper } from "@material-ui/core";

// styles
import useStyles from "./styles";

const Contacts = () => {
  const dispatch = useDispatch();
  const { event, contacts } = useSelector((state) => state.events);
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];
  console.log("contacts page: ", contacts.contact);

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getAllContacts());
  }, [id, dispatch]);
  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Typography variant="h6" className={classes.title}>
                Contacts
              </Typography>
              {contacts.contact?.map((item) => (
                <>
                  {item.ID_EVENT === id ? (
                    <Paper
                      elevation={2}
                      style={{ margin: "20px", borderRadius: "15px" }}
                    >
                      <Grid
                        style={{
                          display: "flex",
                          margin: "10px",
                        }}
                      >
                        <Typography variant="h6" style={{ width: "20%" }}>
                          EMAIL:{" "}
                        </Typography>
                        <Typography variant="h6" style={{ width: "80%" }}>
                          {item.EMAIL}
                        </Typography>
                      </Grid>
                      <Grid style={{ display: "flex", margin: "10px" }}>
                        <Typography variant="h6" style={{ width: "20%" }}>
                          DATE:{" "}
                        </Typography>
                        <Typography variant="h6" style={{ width: "80%" }}>
                          {item.DATE}
                        </Typography>
                      </Grid>
                      <Grid style={{ display: "flex", margin: "10px" }}>
                        <Typography variant="h6" style={{ width: "20%" }}>
                          NOM:{" "}
                        </Typography>
                        <Typography variant="h6" style={{ width: "80%" }}>
                          {item.NOM}
                        </Typography>
                      </Grid>
                      <Grid style={{ display: "flex", margin: "10px" }}>
                        <Typography variant="h6" style={{ width: "20%" }}>
                          MESSAGE:{" "}
                        </Typography>
                        <Typography variant="h6" style={{ width: "80%" }}>
                          {item.MSG}
                        </Typography>
                      </Grid>
                      <Grid style={{ display: "flex", margin: "10px" }}>
                        <Typography variant="h6" style={{ width: "20%" }}>
                          SPECIALITE:{" "}
                        </Typography>
                        <Typography variant="h6" style={{ width: "80%" }}>
                          {item.SPECIALITE}
                        </Typography>
                      </Grid>
                    </Paper>
                  ) : (
                    <></>
                  )}
                </>
              ))}
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Contacts;
