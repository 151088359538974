// hooks
import { useNavigate } from "react-router-dom";

// m-ui components
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
} from "@material-ui/core";

// components
import LoadingCard from "../LoadingCard/LoadingCard";

// image path
import { IMAGE_PATH } from "../../constants/actionTypes";

// styles
import useStyles from "./style";
const EventCard = ({ item }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <>
      {!item ? (
        <LoadingCard type="card" />
      ) : (
        <Card
          className={classes.card}
          onClick={() => navigate(`/event/${item?.ID_EVENT}`)}
        >
          <CardActionArea>
            <CardMedia
              component="img"
              height="100"
              image={IMAGE_PATH + item?.IMG}
              alt={item?.IMG}
              className={classes.media}
            />
            <CardContent
              sx={{
                textAlign: "center",
                margin: "10px 0px",
                overFlow: "hidden",
              }}
            >
              <Typography
                gutterBottom
                variant="body2"
                style={{ fontWeight: 600 }}
              >
                {item?.LIEU}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  color: "gray",
                  fontSize: 12,
                }}
              >
                {item?.DESCRIPTION.toLowerCase()}
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      )}
    </>
  );
};

export default EventCard;
