// hooks
import { useNavigate } from "react-router-dom";

// m-ui components
import {
  Card,
  CardContent,
  CardMedia,
  Typography,
  CardActionArea,
  Grid,
} from "@material-ui/core";

// image
import test from "../../assets/test.jpg";

// styles
import useStyles from "./styles";
const Event = ({ id }) => {
  const navigate = useNavigate();
  const classes = useStyles();
  return (
    <Grid className={classes.event}>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/e-posters`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              E poster
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/program`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              Programme
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/sponsors`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              Sponsors
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/confirenciers`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              Confirencier
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/contacts`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              Contact
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/apropos`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              A propos
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/galeries`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              Galerie
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/hotels`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              Hotels partenaires
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        onClick={() => navigate(`/event/${id}/comite-scientifique`)}
        className={classes.card}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              comite Scientifique
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
      <Card
        sx={{ width: 150 }}
        className={classes.card}
        onClick={() => navigate(`/event/${id}/comite-organisation`)}
      >
        <CardActionArea>
          <CardMedia
            component="img"
            height="100"
            image={test}
            alt="green iguana"
          />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography gutterBottom variant="h6" component="div">
              comite Organisation
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default Event;
