// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import { Container, Typography, Grid, Paper } from "@material-ui/core";

// components
import LeftNav from "../../components/leftNav/LeftNav";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";

// pdf constant
import { PDF_PATH } from "../../constants/actionTypes";

// actions
import { getEposter, getEvent } from "../../actions/events";

// styles
import useStyles from "./styles";

const Rubrique = () => {
  const dispatch = useDispatch();
  const { event, eposter, eposterId } = useSelector((state) => state.events);

  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];
  const ID_EPOSTER = eposterId;

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getEposter(ID_EPOSTER));
  }, [id, ID_EPOSTER, dispatch]);

  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Grid className={classes.flex}>
                <Typography variant="h6" className={classes.name}>
                  RUBRIQUE
                </Typography>
                <Typography variant="h6" className={classes.nameTitle}>
                  {eposter?.RUBRIQUE}
                </Typography>
              </Grid>
              <Grid className={classes.flex}>
                <Typography variant="h6" className={classes.name}>
                  INTITULE
                </Typography>
                <Typography variant="h6" className={classes.nameTitle}>
                  {eposter?.TITRE}
                </Typography>
              </Grid>
              <Grid className={classes.flex}>
                <Typography variant="h6" className={classes.name}>
                  NOM
                </Typography>
                <Typography variant="h6" className={classes.nameTitle}>
                  {eposter?.NOM}
                </Typography>
              </Grid>
              <Grid className={classes.flex}>
                <Typography variant="h6" className={classes.name}>
                  SPECIALITE
                </Typography>
                <Typography variant="h6" className={classes.nameTitle}>
                  {eposter?.SPECIALITE}
                </Typography>
              </Grid>
              <Grid className={classes.flex}>
                <Typography variant="h6" className={classes.name}>
                  GRADE
                </Typography>
                <Typography variant="h6" className={classes.nameTitle}>
                  {eposter?.GRADE}
                </Typography>
              </Grid>
            </Paper>
          </Grid>
          <Grid className={classes.pdf}>
            <iframe
              src={PDF_PATH + eposter?.PDF}
              className={classes.iframe}
              title={eposter?.NOM}
            />
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Rubrique;
