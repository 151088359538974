// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import { Typography, Grid, Paper } from "@material-ui/core";

// components
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// image path
import { IMAGE_PATH } from "../../constants/actionTypes";

// actions
import { getEventComiteOrganisation } from "../../actions/events";

// styles
import useStyles from "./styles";

const SponsorComponent = ({ id }) => {
  const dispatch = useDispatch();

  const { comiteOrganisation } = useSelector((state) => state.events);

  const classes = useStyles();

  const ORGANISATION = "ORGANISATION";

  useEffect(() => {
    dispatch(getEventComiteOrganisation(id, ORGANISATION));
  }, [id, dispatch]);
  return (
    <Grid>
      <Grid className={classes.grid}>
        {!comiteOrganisation?.length ? (
          <Grid style={{ display: "flex" }}>
            <LoadingCard type="card" />
          </Grid>
        ) : (
          <>
            <Typography variant="h6" className={classes.title}>
              Comite: {ORGANISATION}
            </Typography>
            <Grid
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
              }}
            >
              {comiteOrganisation?.map((item) => (
                <Paper className={classes.card} key={item.ID_CONFIRENCIER}>
                  <Grid className={classes.img}>
                    <img
                      src={IMAGE_PATH + item.IMG}
                      alt={item.GRADE}
                      width="95%"
                      height="95%"
                      style={{ borderRadius: "10px" }}
                    />
                  </Grid>
                  <Grid className={classes.rightCard}>
                    <Typography
                      variant="body2"
                      style={{
                        fontWeight: 600,
                        marginTop: "10px",
                      }}
                    >
                      {item.NOM + item.PRENOM}
                    </Typography>
                    <Typography variant="body2" style={{ textAlign: "center" }}>
                      {item.SPECIALITE}
                    </Typography>
                  </Grid>
                </Paper>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SponsorComponent;
