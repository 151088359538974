// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import {
  getArchiveEvents,
  getCurrentEvent,
  getNextEvent,
} from "../../actions/events";

// m-ui components
import { Container, Grid, Paper, Typography } from "@material-ui/core";

// components
import LeftNav from "../../components/leftNav/LeftNav";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import EventCard from "../../components/eventCard/EventCard";
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// styles
import useStyles from "./styles";

const Dashboard = () => {
  //hooks
  const dispatch = useDispatch();

  // get the arrays from redux store
  const { current, archive, next } = useSelector((state) => state.events);

  //styles
  const classes = useStyles();

  // calling the actions before the render of the page
  useEffect(() => {
    dispatch(getCurrentEvent());
    dispatch(getNextEvent());
    dispatch(getArchiveEvents());
  }, [dispatch]);

  return (
    <Grid className={classes.main}>
      {/* left side that containes the left navigation bar */}
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      {/* right side that containes all of the dashboard components */}
      <Grid className={classes.right}>
        <Container>
          {/* top navigation bar */}
          <TopNav />
          {/* header that containes the add event button */}
          <Header />
          {/* the current event component */}
          {!current ? (
            <Grid style={{ display: "flex" }}>
              <LoadingCard type="card" />
              <LoadingCard type="card" />
              <LoadingCard type="card" />
            </Grid>
          ) : (
            <CurrentEvent classPath="main" item={current} />
          )}
          {/* next events */}
          <Typography variant="h4" className={classes.slideTitle}>
            Next
          </Typography>
          <Paper elevation={6} className={classes.slide}>
            {/* looping around the event card */}
            {!next?.length ? (
              <Grid style={{ display: "flex" }}>
                <LoadingCard type="card" />
                <LoadingCard type="card" />
                <LoadingCard type="card" />
              </Grid>
            ) : (
              next.map((itm) => {
                return <EventCard item={itm} key={itm.ID_EVENT} />;
              })
            )}
          </Paper>
          {/* archived events */}
          <Typography variant="h4" className={classes.slideTitle}>
            Archive
          </Typography>
          <Paper elevation={6} className={classes.slide}>
            {/* looping around the event card */}
            {/* testingif the array is null */}
            {!archive?.length ? (
              <Grid style={{ display: "flex" }}>
                <LoadingCard type="card" />
                <LoadingCard type="card" />
                <LoadingCard type="card" />
              </Grid>
            ) : (
              archive.map((ach) => {
                return <EventCard item={ach} key={ach.ID_EVENT} />;
              })
            )}
          </Paper>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
