import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

// for creating the store
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';

// the root reducers
import { reducers } from './reducers'

// creating the store
const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(thunk)))

// creating the root element
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <Provider store={store}>
    <App />
  </Provider>
);