// hooks
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// m-ui components
import { Paper, Grid, Typography } from "@material-ui/core";

// actions
import { getEventWords } from "../../actions/events";

// styles
import useStyles from "./styles";

// images

import { IMAGE_PATH } from "../../constants/actionTypes";
import LoadingCard from "../../components/LoadingCard/LoadingCard";

const Mots = ({ id }) => {
  const dispatch = useDispatch();
  const { eventWords } = useSelector((state) => state.events);
  const allWords = [];
  allWords.push(eventWords);
  const type = "PRESIDENT";

  const classes = useStyles();

  useEffect(() => {
    dispatch(getEventWords(id, type));
  }, [id, dispatch]);
  return (
    <Paper className={classes.paperWord} elevation={6}>
      {!allWords ? (
        <LoadingCard type="line" />
      ) : (
        <>
          {allWords?.map((item) => (
            <Grid className={classes.word}>
              <Grid className={classes.top}>
                <Grid className={classes.image}>
                  <img
                    className={classes.img}
                    src={IMAGE_PATH + item?.IMG}
                    alt={item?.TYPE}
                    width="40px"
                    height="40px"
                  />
                </Grid>
                <Grid className={classes.title}>
                  <Typography variant="h6">{item.NOM}</Typography>
                </Grid>
              </Grid>
              <Grid className={classes.bottomWord}>
                <Typography variant="body2">{item.CONTENU}</Typography>
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Paper>
  );
};

export default Mots;
