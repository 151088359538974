import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    leftNav: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: '#00276A',
        width: '85px',
        height: '100vh'
    },
    logo: {
        margin: '20px 0px',
    },

    title: {
        color: 'white',
        fontSize: '12px',
        letterSpacing: '2px',
        textAlign: 'center'
    },
    list: {
        marginTop: '40px',
        width: '100%'
    },
    linkName: {
        fontSize: '16px',
        fontWeight: '500'
    },
    item: {
        textAlign: 'center',
        marginBottom: '20px',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: '#00276A',
        color: 'white',
        borderRadius: '15px 0px 0px 15px',
        padding: '4px 0px',
        '&:hover': {
            // color: "#00276A",
            color: '#FCB026',
        },
    },
    selected: {
        textAlign: 'center',
        marginBottom: '20px',
        cursor: 'pointer',
        width: '100%',
        backgroundColor: 'whitesmoke',
        color: '#00276A',
        borderRadius: '15px 0px 0px 15px',
        padding: '4px 0px',
    },

    [theme.breakpoints.down('md')]: {

    },

}))