import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

    main: {
        backgroundColor: '#00276A',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    right: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    left: {
        width: '80px',
    },
    mainPage: {
        display: 'flex',
        justifyContent: 'space-between',
        overflowY: 'scroll',
    },
    rightMain: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    mainRight: {
        borderRadius: '15px',
        margin: '20px 0px',
        width: '70%',
        padding: '20px',
    },
    rightEvent: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    img: {
        width: '30%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rightCard: {
        width: '70%',
        textAlign: 'center',
        overflow: 'hidden'
    },
    title: {
        margin: '20px 0px',
        fontSize: '28px'
    },
    card: {
        display: 'flex',
        width: '300px',
        height: '100px',
        borderRadius: '10px',
        margin: '10px',
        border: '1px light #00276A',
        boxShadow: '1px 1px 2px #00276A',
    },
    [theme.breakpoints.down('md')]: {

    },

}))