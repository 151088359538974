
// images path
export const IMAGE_PATH = 'https://www.databridges.dz/API/IMG/';
export const PDF_PATH = 'https://www.databridges.dz/API/PDF/';

// loading
export const START_LOADING = 'START_LOADING';
export const END_LOADING = 'END_LOADING';
// events
// export const GET_ALL_EVENTS = 'GET_ALL_EVENTS';
export const GET_CURRENT_EVENTS = 'GET_CURRENT_EVENTS';
export const GET_NEXT_EVENTS = 'GET_NEXT_EVENTS';
export const GET_ARCHIVE_EVENTS = 'GET_ARCHIVE_EVENTS';
export const GET_EVENTS_BY_ID = 'GET_EVENTS_BY_ID';
export const GET_EVENTS_WORDS = 'GET_EVENTS_WORDS';

export const GET_APROPOS = 'GET_APROPOS';
export const GET_HOTELS = 'GET_HOTELS';
export const GET_GALERIES = 'GET_GALERIES';
export const GET_COMITE_SCIENTIFIQUE = 'GET_COMITE_SCIENTIFIQUE';
export const GET_COMITE_ORGANISATION = 'GET_COMITE_ORGANISATION';
export const GET_CONFIRENCIER = 'GET_CONFIRENCIER';
export const GET_ALL_EPOSTERS_BY_EVENT = 'GET_ALL_EPOSTERS_BY_EVENT';
export const GET_EVENT_ALL_EPOSTERS = 'GET_EVENT_ALL_EPOSTERS';
export const GET_EPOSTER_BY_ID = 'GET_EPOSTER_BY_ID';
export const GET_EVENT_SPONSORS_GOLD = 'GET_EVENT_SPONSORS_GOLD';
export const GET_EVENT_SPONSORS_BRONZE = 'GET_EVENT_SPONSORS_BRONZE';
export const GET_EVENT_CONTACTS = 'GET_EVENT_CONTACTS';


export const EPOSTER_ID_LOCALSTORAGE = 'EPOSTER_ID_LOCALSTORAGE';

// post 
export const CREATE_EVENT = 'CREATE_EVENT';

