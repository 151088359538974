// hooks
import { useNavigate } from "react-router-dom";

// m-ui components
import { Grid, Typography } from "@material-ui/core";

// styles
import useStyles from "./styles";

// logo
import Logo from "../../assets/logo.png";

// icons
import { MdOutlineEvent, MdLogout } from "react-icons/md";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";

const LeftNav = () => {
  const navigate = useNavigate();
  const classes = useStyles();

  const pathname = window.location.pathname;

  return (
    <Grid className={classes.leftNav}>
      {/* logo */}
      <Grid className={classes.logo}>
        <img src={Logo} alt="docthus logo" width="50px" height="50px" />
      </Grid>
      {/* app name */}
      <Grid>
        <Typography className={classes.title} variant="body1">
          DOCTHUS EVENTS
        </Typography>
      </Grid>
      {/* list items */}
      <Grid className={classes.list}>
        {/* fist item */}
        <Grid
          className={`${
            pathname === "/" ? `${classes.selected}` : `${classes.item}`
          }`}
        >
          <Grid onClick={() => navigate("/")}>
            <MdOutlineEvent size="25" />
            <h6 className={classes.linkName}>Events</h6>
          </Grid>
        </Grid>
        {/* ################## */}
        <Grid
          className={`${
            pathname === "/contacts" ? `${classes.selected}` : `${classes.item}`
          }`}
        >
          <Grid onClick={() => navigate("/contacts")}>
            <BsFillTelephoneFill size="25" />
            <h6 className={classes.linkName}>Contacts</h6>
          </Grid>
        </Grid>
        {/* ################## */}
        <Grid
          className={`${
            pathname === "/settings" ? `${classes.selected}` : `${classes.item}`
          }`}
        >
          <Grid onClick={() => navigate("/settings")}>
            <IoMdSettings size="25" />
            <h6 className={classes.linkName}>Settings</h6>
          </Grid>
        </Grid>
        {/* ################## */}
        <Grid
          className={`${
            pathname === "/logout" ? `${classes.selected}` : `${classes.item}`
          }`}
        >
          <Grid onClick={() => {}}>
            <MdLogout size="25" />
            <h6 className={classes.linkName}>Logout</h6>
          </Grid>
        </Grid>
        {/* ################## */}
      </Grid>
    </Grid>
  );
};

export default LeftNav;
