// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Paper,
} from "@material-ui/core";

// components
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import LeftNav from "../../components/leftNav/LeftNav";
import TopNav from "../../components/topNav/TopNav";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import Header from "../../components/header/Header";

// actions
import { getEvent, getHotels } from "../../actions/events";

// images path
import { IMAGE_PATH } from "../../constants/actionTypes";

// styles
import useStyles from "./styles";

const Hotels = () => {
  const dispatch = useDispatch();
  const { event, hotels } = useSelector((state) => state.events);
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getHotels(id));
  }, [id, dispatch]);
  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Typography variant="h6" className={classes.title}>
                Hotels partenaires
              </Typography>
              <Grid className={classes.rightEvent}>
                {!hotels.length ? (
                  <Grid style={{ display: "flex" }}>
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                  </Grid>
                ) : (
                  hotels?.map((item) => (
                    <Card
                      // sx={{ width: 200 }}
                      onClick={() => window.open(`${item.URL}`, "_blank")}
                      // component={Link} to=`${URL}`
                      className={classes.card}
                      style={{
                        width: "250px",
                        margin: "10px",
                        borderRadius: "10px",
                      }}
                      key={item.ID_HOTEL}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={IMAGE_PATH + item.IMG}
                          alt={item.NOM}
                          className={classes.media}
                        />
                        <CardContent
                          style={{ textAlign: "center", height: "50px" }}
                        >
                          <Typography
                            gutterBottom
                            variant="body2"
                            component="div"
                            style={{ fontWeight: 600 }}
                          >
                            {item.NOM}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  ))
                )}
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Hotels;
