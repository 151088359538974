import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    main: {
        backgroundColor: '#00276A',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    right: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    left: {
        width: '80px',
    },
    mainPage: {
        display: 'flex',
        justifyContent: 'space-between',
        overflowY: 'scroll',
    },
    rightMain: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    mainRight: {
        borderRadius: '15px',
        margin: '20px 0px',
        width: '70%',
        padding: '20px',
    },
    title: {
        margin: '20px 10px',
        fontSize: '28px'
    },
    paper: {
        width: '250px',
        height: '100px',
        margin: '10px',
        overflow: 'hidden',
        cursor: 'pointer',
        '&:hover': {
            boxShadow: '1px 1px 6px #00276A'
        }
    },
    [theme.breakpoints.down('md')]: {

    },

}))