// m-ui components
import { Grid, Typography } from "@material-ui/core";

// icons
import { BiSearch, BiBell } from "react-icons/bi";
import { BsFillPersonFill } from "react-icons/bs";

// styles
import useStyles from "./styles.js";

const TopNav = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.topNav}>
      {/* title */}
      <div>
        <Typography className={classes.title} variant="h4">
          Events
        </Typography>
      </div>
      {/* right side containes actions */}
      <Grid className={classes.icons}>
        <span className={classes.spanIcons}>
          <BiSearch size="25" />
        </span>
        <span className={classes.spanIcons}>
          <BiBell size="25" />
        </span>
        <span className={classes.spanIcons}>
          <BsFillPersonFill size="25" />
        </span>
      </Grid>
    </Grid>
  );
};

export default TopNav;
