import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    main: {
        backgroundColor: '#00276A',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    right: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    left: {
        width: '80px'
    },
    mainPage: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
    },
    rightEvent: {
        borderRadius: '15px',
        margin: '20px 0px',
        width: '70%',
        padding: '20px'
    },
    title: {
        marginBottom: '20px',
        fontSize: '28px'
    },
    contenu: {
        fontSize: '14px',
    },
    [theme.breakpoints.down('md')]: {

    },

}))