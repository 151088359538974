import { Skeleton, Stack, Box } from "@mui/material";
const LoadingCard = (type) => {
  return (
    <>
      {type !== "card" ? (
        <Stack spacing={1} m="auto 20px">
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rectangular" width={210} height={100} />
        </Stack>
      ) : (
        <Box>
          <Skeleton />
          <Skeleton animation="wave" />
          <Skeleton animation={false} />
        </Box>
      )}
    </>
  );
};

export default LoadingCard;
