// hooks
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// pages
import ComiteScientifique from "./pages/Comite/ComiteScientifique";
import ComiteOrganisation from "./pages/Comite/ComiteOrganisation";
import Confirenciers from "./pages/Confirenciers/Confirenciers";
import Rubriques from "./pages/Rubriques/Rubriques";
import Dashboard from "./pages/dashboard/Dashboard";
import EventPage from "./pages/EventPage/EventPage";
import Rubrique from "./pages/Rubrique/Rubrique";
import Sponsors from "./pages/Sponsors/Sponsors";
import Galeries from "./pages/Galeries/Galeries";
import Contacts from "./pages/Contacts/Contacts";
import Eposter from "./pages/Eposters/Eposter";
import Apropos from "./pages/Apropos/Apropos";
import Program from "./pages/Program/Program";
import Hotels from "./pages/Hotels/Hotels";
// add pages
import AddEvent from "./pages/AddEvent/AddEvent";

const App = () => {
  return (
    <Router>
      <Routes>
        {/* privete route */}
        {/* <Route path='/dashboard' element={<ProtectedRoute />}>
          <Route exact path='/dashboard' element={<Dashboard />} />
        </Route> */}
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/event/:id" element={<EventPage />} />
        <Route exact path="/event/:id/apropos" element={<Apropos />} />
        <Route exact path="/event/:id/hotels" element={<Hotels />} />
        <Route exact path="/event/:id/e-posters" element={<Eposter />} />
        <Route exact path="/event/:id/sponsors" element={<Sponsors />} />
        <Route exact path="/event/:id/e-posters" element={<Eposter />} />
        <Route exact path="/event/:id/galeries" element={<Galeries />} />
        <Route exact path="/event/:id/program" element={<Program />} />
        <Route exact path="/event/:id/contacts" element={<Contacts />} />
        <Route
          exact
          path="/event/:id/comite-scientifique"
          element={<ComiteScientifique />}
        />
        <Route
          exact
          path="/event/:id/comite-organisation"
          element={<ComiteOrganisation />}
        />
        <Route
          exact
          path="/event/:id/e-posters/:rubrique"
          element={<Rubriques />}
        />
        <Route
          exact
          path="/event/:id/e-posters/:rubrique/:title"
          element={<Rubrique />}
        />
        <Route
          exact
          path="/event/:id/confirenciers"
          element={<Confirenciers />}
        />
        <Route exact path="/add-event" element={<AddEvent />} />
      </Routes>
    </Router>
  );
};

export default App;
