// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getEvent, getApropos } from "../../actions/events";

// components
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import LeftNav from "../../components/leftNav/LeftNav";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// m-ui components
import { Container, Typography, Grid, Paper } from "@material-ui/core";

// styles
import useStyles from "./styles";

const Apropos = () => {
  const dispatch = useDispatch();
  const { event, apropos } = useSelector((state) => state.events);
  const classes = useStyles();

  // getting the id from the link
  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getApropos(id));
  }, [id, dispatch]);
  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.rightEvent}>
              <Typography variant="h6" className={classes.title}>
                A propos
              </Typography>
              <Typography variant="body2" className={classes.contenu}>
                {!apropos ? <LoadingCard type="line" /> : apropos?.CONTENU}
              </Typography>
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Apropos;
