// hooks
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import { Container, Typography, Grid, Paper } from "@material-ui/core";

// components
import LeftNav from "../../components/leftNav/LeftNav";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";

// actions
import {
  getEvent,
  getEventAllEposters,
  setEposterIdToRedux,
} from "../../actions/events";

// styles
import useStyles from "./styles";

const Rubriques = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { event, epostersByEvent } = useSelector((state) => state.events);
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];
  const rubrique = window.location.pathname.split("/")[4];

  const setEposterId = (id, rubrique, eposter_id) => {
    dispatch(setEposterIdToRedux(eposter_id));
    navigate(`/event/${id}/e-posters/${rubrique}/${eposter_id}`);
  };

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getEventAllEposters(id, rubrique));
  }, [id, dispatch, rubrique]);
  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Typography variant="h6" className={classes.title}>
                E-poster: {rubrique}
              </Typography>
              <Grid className={classes.grid}>
                {!epostersByEvent.length ? (
                  <Grid style={{ display: "flex" }}>
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                  </Grid>
                ) : (
                  <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                    {epostersByEvent?.map((item) => (
                      <Paper
                        className={classes.paper}
                        key={item.ID_EPOSTER}
                        onClick={() =>
                          setEposterId(id, rubrique, item.ID_EPOSTER)
                        }
                      >
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {item.TITRE.toLowerCase()}
                        </Typography>
                      </Paper>
                    ))}
                  </Grid>
                )}
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Rubriques;
