// hooks
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

// actions
import { addEvent } from "../../actions/events";

// components

// m-ui components
import { Paper, Grid, Typography, TextField, Button } from "@material-ui/core";

// styles
import useStyles from "./styles";

const EventForm = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [event, setEvent] = useState({
    DATE: "",
    PERIODE: "",
    TITRE: "",
    IMG: "",
    DESCRIPTION: "",
    LIEU: "",
    LOCALISATION: "",
    PROGRAMME: "",
    PROGRAMME_PDF: "",
    POURQUOI: "",
    ETAT: "",
    FACEBOOK: "",
    INSTAGRAM: "",
    SITE: "",
    LINKIDIN: "",
    email: "",
    phone: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addEvent(event));

    clear();
  };

  const clear = () => {
    setEvent({
      DATE: "",
      PERIODE: "",
      TITRE: "",
      IMG: "",
      DESCRIPTION: "",
      LIEU: "",
      LOCALISATION: "",
      PROGRAMME: "",
      PROGRAMME_PDF: "",
      POURQUOI: "",
      ETAT: "",
      FACEBOOK: "",
      INSTAGRAM: "",
      SITE: "",
      LINKIDIN: "",
      email: "",
      phone: "",
    });
  };

  return (
    <Paper className={classes.paper} elevetaion={3}>
      <form
        autoComplete="off"
        noValidate
        className={classes.form}
        onSubmit={handleSubmit}
      >
        <Typography variant="h6" className={classes.title}>
          Add Event
        </Typography>
        <TextField
          className={classes.textfield}
          name="DATE"
          variant="outlined"
          label="Date"
          fullWidth
          value={event.DATE}
          onChange={(e) => setEvent({ ...event, DATE: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="TITRE"
          variant="outlined"
          label="Titre"
          fullWidth
          value={event.TITRE}
          onChange={(e) => setEvent({ ...event, TITRE: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="PERIODE"
          variant="outlined"
          label="Periode"
          fullWidth
          value={event.PERIODE}
          onChange={(e) => setEvent({ ...event, PERIODE: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="DESCRIPTION"
          variant="outlined"
          label="Description"
          fullWidth
          value={event.DESCRIPTION}
          onChange={(e) => setEvent({ ...event, DESCRIPTION: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="LIEU"
          variant="outlined"
          label="Lieu"
          fullWidth
          value={event.LIEU}
          onChange={(e) => setEvent({ ...event, LIEU: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="LOCALISATION"
          variant="outlined"
          label="Localisation"
          fullWidth
          value={event.LOCALISATION}
          onChange={(e) => setEvent({ ...event, LOCALISATION: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="POURQUOI"
          variant="outlined"
          label="Pourque"
          fullWidth
          value={event.POURQUOI}
          onChange={(e) => setEvent({ ...event, POURQUOI: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="ETAT"
          variant="outlined"
          label="Etat"
          fullWidth
          value={event.ETAT}
          onChange={(e) => setEvent({ ...event, ETAT: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="FACEBOOK"
          variant="outlined"
          label="Facebook"
          fullWidth
          value={event.FACEBOOK}
          onChange={(e) => setEvent({ ...event, FACEBOOK: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="INSTAGRAM"
          variant="outlined"
          label="Instagram"
          fullWidth
          value={event.INSTAGRAM}
          onChange={(e) => setEvent({ ...event, INSTAGRAM: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="LINKIDIN"
          variant="outlined"
          label="Linkedin"
          fullWidth
          value={event.LINKIDIN}
          onChange={(e) => setEvent({ ...event, LINKIDIN: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="SITE"
          variant="outlined"
          label="Site"
          fullWidth
          value={event.SITE}
          onChange={(e) => setEvent({ ...event, SITE: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="email"
          variant="outlined"
          label="Email"
          fullWidth
          value={event.email}
          onChange={(e) => setEvent({ ...event, email: e.target.value })}
        />
        <TextField
          className={classes.textfield}
          name="Phone"
          variant="outlined"
          label="phone"
          fullWidth
          value={event.phone}
          onChange={(e) => setEvent({ ...event, phone: e.target.value })}
        />
        <Grid className={classes.grid}>
          <Typography variant="h6" style={{ width: "40%" }}>
            Event Image
          </Typography>
          <input
            type="file"
            name="IMG"
            accept="image/*"
            className={classes.input}
            onChange={(e) =>
              setEvent({ ...event, IMG: e.target.files[0].name })
            }
          />
        </Grid>
        <Grid className={classes.grid}>
          <Typography variant="h6" style={{ width: "40%" }}>
            Event Programme
          </Typography>
          <input
            type="file"
            name="PROGRAMME"
            accept="image/*"
            className={classes.input}
            onChange={(e) =>
              setEvent({ ...event, PROGRAMME: e.target.files[0].name })
            }
          />
        </Grid>
        <Grid className={classes.grid}>
          <Typography variant="h6" style={{ width: "40%" }}>
            Event pdf Programme
          </Typography>
          <input
            type="file"
            name="PROGRAMME_PDF"
            accept="application/pdf"
            className={classes.input}
            onChange={(e) =>
              setEvent({ ...event, PROGRAMME_PDF: e.target.files[0].name })
            }
          />
        </Grid>
        <Button
          type="submit"
          className={classes.btnSubmit}
          variant="contained"
          size="large"
          fullWidth
        >
          Add
        </Button>
        <Button
          variant="contained"
          className={classes.btnClear}
          size="small"
          fullWidth
          onClick={clear}
        >
          Clear
        </Button>
      </form>
    </Paper>
  );
};

export default EventForm;
