// hooks
import { useNavigate } from "react-router-dom";

// m-ui components
import { Button, Grid } from "@material-ui/core";
// styles
import useStyles from "./styles";

// icons
import { BsFillArrowLeftSquareFill } from "react-icons/bs";
import { setEposterIdToRedux } from "../../actions/events";
import { useDispatch } from "react-redux";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();

  const path = window.location.pathname;

  const back = () => {
    dispatch(setEposterIdToRedux(null));
    navigate(-1);
  };

  return (
    <Grid className={classes.header}>
      <BsFillArrowLeftSquareFill
        size="35"
        onClick={() => back()}
        className={classes.arrow}
      />
      {path === "/" ? (
        <Button
          className={classes.btn}
          variant="outlined"
          size="medium"
          onClick={() => navigate(`/add-event`)}
        >
          Add Event
        </Button>
      ) : (
        <></>
      )}
    </Grid>
  );
};

export default Header;
