// hooks
import { useEffect } from "react";
import { useDispatch } from "react-redux";

// m-ui components
import { Container, Grid } from "@material-ui/core";

// components
import LeftNav from "../../components/leftNav/LeftNav";
import Header from "../../components/header/Header";

// styles
import useStyles from "./styles";
import EventForm from "../../components/eventForm/EventForm";

const AddEvent = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    //
  }, [id, dispatch]);
  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <Header />
          <Grid className={classes.mainPage}>
            <EventForm />
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default AddEvent;
