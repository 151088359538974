// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import {
  Container,
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardMedia,
  Paper,
} from "@material-ui/core";

// components
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import LeftNav from "../../components/leftNav/LeftNav";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";

// actions
import { getEvent, getGaleries } from "../../actions/events";

// images path
import { IMAGE_PATH } from "../../constants/actionTypes";

// styles
import useStyles from "./styles";

const Galeries = () => {
  const { event, galories } = useSelector((state) => state.events);
  const dispatch = useDispatch();
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getGaleries(id));
  }, [id, dispatch]);

  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Typography variant="h6" className={classes.title}>
                Galleries
              </Typography>
              <Grid className={classes.rightEvent}>
                {!galories.length ? (
                  <Grid style={{ display: "flex" }}>
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                  </Grid>
                ) : (
                  galories?.map((item) => (
                    <Card
                      className={classes.card}
                      style={{
                        width: "250px",
                        margin: "10px",
                        borderRadius: "10px",
                      }}
                      key={item.ID_GALERIE}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="140"
                          image={IMAGE_PATH + item.IMG}
                          alt={item.ID_GALERIE}
                          className={classes.media}
                        />
                      </CardActionArea>
                    </Card>
                  ))
                )}
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Galeries;
