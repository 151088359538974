import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    topNav: {
        display: 'flex',
        height: '60px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    title: {
        color: '#00276A'
    },
    icons: {
        display: 'flex',
        width: '150px',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    spanIcons: {
        borderRadius: '50%',
        backgroundColor: 'lightgray',
        padding: '8px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: "white",
            backgroundColor: '#00276A',
        },
    },
    
    [theme.breakpoints.down('md')]: {


    },

}))