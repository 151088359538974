import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    // mainPage: {
    //     display: 'flex',
    //     justifyContent: 'center',
    //     height: '100vh',
    //     backgroundColor: '#00276A',
    // },
    // mainContainer: {
    //     borderRadius: '15px 0px 0px 15px',
    //     backgroundColor: 'whitesmoke',
    // },
    // // event page
    rightEvent: {
        margin: '20px 0px',
        width: '70%'
    },
    mainPage: {
        backgroundColor: '#00276A',
        display: 'flex',
        justifyContent: 'center',
        height: '100vh'
    },
    right: {
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
        width: '100%',
        backgroundColor: 'whitesmoke'
    },
    left: {
        width: '80px'
    },
    main: {
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '15px 0px 0px 15px',
        overflowY: 'scroll',
    },
    btns: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '20px'
    },
    btn: {
        color: 'white',
        backgroundColor: '#00276A',
        marginLeft: '20px',
        '&:hover': {
            color: "#00276A",
            backgroundColor: 'white',
        },
    },
    btnSelected: {
        color: "#00276A",
        backgroundColor: 'white',
        marginLeft: '20px',
    },
    // mots des presidents
    paperWord: {
        padding: '10px 0px',
    },
    word: {
        margin: '0px 20px',
    },
    top: {
        display: 'flex',
        marginTop: '20px',
    },
    image: {
        display: 'flex',
        width: '40px'
    },
    img: {
        borderRadius: '50%',
    },
    title: {
        color: '#FCB026',
        margin: '0px auto'
    },
    bottomWord: {
        marginTop: '10px'
    },
    // event
    event: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    card: {
        marginTop: '10px',
        marginRight: '10px',
        width: '150px',
        textAlign: 'center'
    },
    // pourquoi assister
    why: {
        margin: '0px 20px'
    },

    [theme.breakpoints.down('md')]: {

    },

}))