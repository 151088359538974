import axios from "axios";

const API = axios.create({ baseURL: 'https://databridges.dz/API' });
const NEW_API = axios.create({ baseURL: 'https://docthusevent.databridges.dz/public/api' })

// get all events
// export const getAllEvents = () => API.get('/getAllEvent.php');

// get the current event
export const getCurrentEvent = () => API.get('/getCurrentEvent.php');

// get next events
export const getNextEvent = () => API.get('/getNextEvent.php');

// archive events
export const getArchiveEvents = () => API.get('/getLastEvent.php');

// get event by id
export const getEventById = (id) => API.get(`/getEventByID.php?ID=${id}`);

// all about an event ##################################################

// get mots
export const getEventWords = (id, type) => API.get(`/getMotByEvent.php?ID=${id}&TYPE=${type}`);

//get all eposters of an event
export const getAllEposterByEvent =(id) => API.get(`/getAllrubriqueByEvent.php?ID=${id}`)

// get all pdf by an eposter
export const getEventAllEposters = (id, rubrique) => API.get(`/getAllEposterByEvent.php?ID=${id}&RUB=${rubrique}`);

// get eposter by id
export const getEposterById = (id) => API.get(`/getEposterByID.php?ID=${id}`)


// get confirencier
export const getEventConfirencie = (id) => API.get(`/getConfirencierByEvent.php?ID=${id}`);

// get a propos
export const getEventApropos = (id_event) => API.get(`/getapproposByEvent.php?ID=${id_event}`);

// get hotels
export const getEventHotels = (id) => API.get(`/getHotelByEvent.php?ID=${id}`);

// get galerie
export const getEventGalerie =(id) => API.get(`/getGalerieByEvent.php?ID=${id}`);

// get sponsore
export const getEventSponsores = (id, type) => API.get(`/getSponsorsByEvent.php?ID=${id}&TYPE=${type}`);

// get comite
export const getEventComite = (id, type) => API.get(`/getComiteByEvent.php?ID=${id}&TYPE=${type}`);

// new api use
// get event contact
export const getEventContacts = () => NEW_API.get(`/contact/readAll`);

// add event
export const createEvent = (event) => NEW_API.post('/event/create', event)