import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
    header: {
        height: '60px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    btn: {
        color: 'white',
        backgroundColor: '#FCB026',
        '&:hover': {
            color: "white",
            backgroundColor: '#00276A',
        },
    },
    arrow: {
        color: '#00276A',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#00276A',
            borderRadius: '5px',
            border: '1px solid #00276A',
            color: 'white',
        },
    },

    [theme.breakpoints.down('md')]: {

    },

}))