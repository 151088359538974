// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// actions
import { getEvent, getConfirenciers } from "../../actions/events";

// components
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import LeftNav from "../../components/leftNav/LeftNav";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import TopNav from "../../components/topNav/TopNav";
import Header from "../../components/header/Header";

// m-ui components
import { Container, Grid, Typography, Paper } from "@material-ui/core";

// styles
import useStyles from "./styles";

// IMAGES PATH
import { IMAGE_PATH } from "../../constants/actionTypes";

const Confirenciers = () => {
  const dispatch = useDispatch();
  const { event, confirenciers } = useSelector((state) => state.events);
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];

  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getConfirenciers(id));
  }, [id, dispatch]);
  return (
    <Grid className={classes.main}>
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              <Typography variant="h6" className={classes.title}>
                Confirencier
              </Typography>
              <Grid className={classes.rightEvent}>
                {!confirenciers.length ? (
                  <>
                    <LoadingCard type="card" />
                    <LoadingCard type="card" />
                  </>
                ) : (
                  confirenciers?.map((item) => (
                    <Paper className={classes.card} key={item.ID_CONFIRENCIER}>
                      <Grid className={classes.img}>
                        <img
                          src={IMAGE_PATH + item.IMG}
                          alt={item.NOM}
                          width="95%"
                          height="95%"
                          style={{ borderRadius: "10px" }}
                        />
                      </Grid>
                      <Grid className={classes.rightCard}>
                        <Typography
                          variant="body2"
                          style={{ fontWeight: 600, marginTop: "20px" }}
                        >
                          {item.NOM + item.PRENOM}
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{ textAlign: "center" }}
                        >
                          {item.SPECIALITE}
                        </Typography>
                      </Grid>
                    </Paper>
                  ))
                )}
              </Grid>
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Confirenciers;
