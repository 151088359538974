// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// m-ui components
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core";

// components
import LoadingCard from "../../components/LoadingCard/LoadingCard";

// image path
import { IMAGE_PATH } from "../../constants/actionTypes";

// actions
import {
  getEventSponsorsBronze,
  getEventSponsorsGold,
} from "../../actions/events";

// styles
import useStyles from "./styles";

const SponsorComponent = ({ id, type }) => {
  const dispatch = useDispatch();

  const { sponsorsGold, sponsorsBronze } = useSelector((state) => state.events);

  const classes = useStyles();

  const GOLD = "GOLDE";
  const BRONZE = "BRONZE";

  useEffect(() => {
    dispatch(getEventSponsorsGold(id, GOLD));
    dispatch(getEventSponsorsBronze(id, BRONZE));
  }, [id, type, dispatch]);
  return (
    <Grid>
      <Grid className={classes.grid}>
        {!sponsorsGold?.length && !sponsorsBronze?.length ? (
          <Grid style={{ display: "flex" }}>
            <LoadingCard type="card" />
          </Grid>
        ) : (
          <>
            <Typography variant="h6" className={classes.title}>
              Sponsors: {GOLD}
            </Typography>
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
              {sponsorsGold?.map((item) => (
                <Card
                  className={classes.card}
                  style={{
                    width: "250px",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  key={item.ID_SPONSOR}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={IMAGE_PATH + item.IMG}
                      alt={item.NOM}
                      className={classes.media}
                    />
                    <CardContent
                      style={{ textAlign: "center", height: "50px" }}
                    >
                      <Typography
                        gutterBottom
                        variant="body2"
                        component="div"
                        style={{ fontWeight: 600 }}
                      >
                        {item.TITRE}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Grid>
            <Typography variant="h6" className={classes.title}>
              Sponsors: {BRONZE}
            </Typography>
            <Grid style={{ display: "flex", flexWrap: "wrap" }}>
              {sponsorsBronze?.map((item) => (
                <Card
                  className={classes.card}
                  style={{
                    width: "250px",
                    margin: "10px",
                    borderRadius: "10px",
                  }}
                  key={item.ID_SPONSOR}
                >
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="140"
                      image={IMAGE_PATH + item.IMG}
                      alt={item.NOM}
                      className={classes.media}
                    />
                    <CardContent
                      style={{ textAlign: "center", height: "50px" }}
                    >
                      <Typography
                        gutterBottom
                        variant="body2"
                        component="div"
                        style={{ fontWeight: 600 }}
                      >
                        {item.TITRE}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SponsorComponent;
