import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({

    // style={{
    //     minWidth: 240,
    //     maxWidth: 250,
    //     marginRight: 20,
    //     textAlign: "center",
    //   }}
    card: {
        minWidth: '240px',
        maxWidth: '240px',
        marginRight: '20px',
        textAlign: 'center',
        '&:hover': {
            boxShadow: '1px 1px 6px #00276A'
        }
    },
    media: {
        '&:hover': {
            transform: 'scale(1.1)',
            transitionDuration: '600ms'
        }
    },
    [theme.breakpoints.down('md')]: {

    },

}))