
// constants
import { START_LOADING, END_LOADING, GET_NEXT_EVENTS, GET_CURRENT_EVENTS, GET_EVENTS_BY_ID, GET_ARCHIVE_EVENTS, GET_ALL_EPOSTERS_BY_EVENT, GET_APROPOS, GET_HOTELS, GET_CONFIRENCIER, GET_EVENTS_WORDS, GET_EVENT_ALL_EPOSTERS, GET_EPOSTER_BY_ID, EPOSTER_ID_LOCALSTORAGE, GET_EVENT_SPONSORS_BRONZE, GET_EVENT_SPONSORS_GOLD, GET_GALERIES, GET_COMITE_ORGANISATION, GET_COMITE_SCIENTIFIQUE, GET_EVENT_CONTACTS, CREATE_EVENT } from "../constants/actionTypes";

// api calls
import * as api from '../api/index.js';

// get current event
export const getCurrentEvent = () => async (dispatch) => {
    try {
        const { data } = await api.getCurrentEvent();
        dispatch({ type: GET_CURRENT_EVENTS, payload: { current: data} });
    } catch (error) {
        console.log('error:', error);
    }
}
// get event contacts
export const getAllContacts = () => async (dispatch) => {
    try {
        const { data } = await api.getEventContacts();
        console.log('data:', data)
        dispatch({ type: GET_EVENT_CONTACTS, payload: data });
    } catch (error) {
        console.log('error:', error);
    }
}

// get next events
export const getNextEvent = () => async (dispatch) => {
    try {
        const { data } = await api.getNextEvent();
        dispatch({ type: GET_NEXT_EVENTS, payload: data });
    } catch (error) {
        console.log('error:', error);
    }
}


// get archived events
export const getArchiveEvents = () => async (dispatch) => {
    try {
        const { data } = await api.getArchiveEvents();
        dispatch({ type: GET_ARCHIVE_EVENTS, payload: data })
    } catch (error) {
        console.log('error:', error)
    }
}

// get by id
export const getEvent = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventById(id);
        dispatch({ type: GET_EVENTS_BY_ID, payload: { event: data } });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// get by id
export const getEventWords = (id, type) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventWords(id, type);
        dispatch({ type: GET_EVENTS_WORDS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// get a propos
export const getApropos = (id_event) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventApropos(id_event);
        dispatch({ type: GET_APROPOS, payload: { apropos: data } });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// get hotels partenaires
export const getHotels = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventHotels(id);
        dispatch({ type: GET_HOTELS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// get hotels partenaires
export const getConfirenciers = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventConfirencie(id);
        dispatch({ type: GET_CONFIRENCIER, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// get hotels partenaires
export const getGaleries = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventGalerie(id);
        dispatch({ type: GET_GALERIES, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

//get all eposters of an event
export const getAllEposterByEvent = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getAllEposterByEvent(id);
        dispatch({ type: GET_EVENT_ALL_EPOSTERS, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

//get all eposters of an event
export const getEventAllEposters = (id, rubrique) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventAllEposters(id, rubrique);
        dispatch({ type: GET_ALL_EPOSTERS_BY_EVENT, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// get eposter by id
export const getEposter = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEposterById(id);
        dispatch({ type: GET_EPOSTER_BY_ID, payload: { eposter: data } });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// set eposter id
export const setEposterIdToRedux = (id) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        dispatch({ type: EPOSTER_ID_LOCALSTORAGE, payload: id });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

//get event sponsors
export const getEventSponsorsGold = (id, type) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventSponsores(id, type);
        dispatch({ type: GET_EVENT_SPONSORS_GOLD, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

//get event sponsors
export const getEventSponsorsBronze = (id, type) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventSponsores(id, type);
        dispatch({ type: GET_EVENT_SPONSORS_BRONZE, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

//get event sponsors
export const getEventComiteScientifique = (id, type) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventComite(id, type);
        dispatch({ type: GET_COMITE_SCIENTIFIQUE, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

//get event sponsors
export const getEventComiteOrganisation = (id, type) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.getEventComite(id, type);
        dispatch({ type: GET_COMITE_ORGANISATION, payload: data });
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error);
    }
}

// add event
export const addEvent = (event) => async (dispatch) => {
    try {
        dispatch({ type: START_LOADING });
        const { data } = await api.createEvent(event);
        dispatch({ type: CREATE_EVENT, payload: data })
        dispatch({ type: END_LOADING });
    } catch (error) {
        console.log('error:', error)
    }
}