import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    paper: {
        width: '80%',
        borderRadius: '15px',
        margin: '30px auto'
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        width: '90%',
        margin: '30px auto',
        justifyContent: 'center',
    },
    title:{
        margin: '5px 0px',
        textAlign: 'center',
    },
    textfield:{
        margin: '5px 0px ',
    },
    grid:{
        display: 'flex'
    },
    input:{
        margin: '10px 0px',
        width: '100%',
    },
    btnSubmit: {
        marginBottom: '10px',
        backgroundColor: '#00276A',
        color: "white",
        '&:hover': {
            color: "black",
            backgroundColor: '#00599b',
            boxShadow: '2px 2px 6px black',
        },
    },
    btnClear: {
        color: 'white',
        backgroundColor: '#FCB026',
        boxShadow: '2px 2px 6px gray',
        '&:hover': {
            color: "black",
            backgroundColor: '#ff6135',
            boxShadow: '2px 2px 6px black',
        },
    },
}));