// hooks
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// actions
import { getEvent, getAllEposterByEvent } from "../../actions/events";

// m-ui components
import { Container, Grid, Paper, Typography } from "@material-ui/core";

// componets
import LeftNav from "../../components/leftNav/LeftNav";
import TopNav from "../../components/topNav/TopNav";
import CurrentEvent from "../../components/currentEvent/CurrentEvent";
import LoadingCard from "../../components/LoadingCard/LoadingCard";
import Header from "../../components/header/Header";

// styles
import useStyles from "./styles";

const Eposter = () => {
  const dispatch = useDispatch();
  const { event, eventAllEposters } = useSelector((state) => state.events);
  const navigate = useNavigate();
  const classes = useStyles();

  const id = window.location.pathname.split("/")[2];
  useEffect(() => {
    dispatch(getEvent(id));
    dispatch(getAllEposterByEvent(id));
  }, [id, dispatch]);
  return (
    <Grid className={classes.main}>
      {/* left side that containes the left navigation bar */}
      <Grid className={classes.left}>
        <LeftNav />
      </Grid>
      <Grid className={classes.right}>
        <Container>
          <TopNav />
          <Header />
          <Grid className={classes.mainPage}>
            {/* current event components */}
            <Grid>
              {!event ? (
                <LoadingCard type="card" />
              ) : (
                <CurrentEvent classPath="event" item={event} />
              )}
            </Grid>
            {/*  */}
            <Paper className={classes.mainRight}>
              {!eventAllEposters.length ? (
                <Grid style={{ display: "flex", marginTop: "20px" }}>
                  <LoadingCard type="card" />
                  <LoadingCard type="card" />
                  <LoadingCard type="card" />
                </Grid>
              ) : (
                <>
                  <Grid>
                    <Typography variant="h6" className={classes.title}>
                      Rubriques
                    </Typography>
                  </Grid>
                  <Grid style={{ display: "flex", flexWrap: "wrap" }}>
                    {eventAllEposters?.map((item) => (
                      <Paper
                        key={item.RUBRIQUE}
                        className={classes.paper}
                        onClick={() =>
                          navigate(`/event/${id}/e-posters/${item.RUBRIQUE}`)
                        }
                      >
                        <Typography variant="body1" style={{ margin: "10px" }}>
                          {item.RUBRIQUE}
                        </Typography>
                      </Paper>
                    ))}
                  </Grid>
                </>
              )}
            </Paper>
          </Grid>
        </Container>
      </Grid>
    </Grid>
  );
};

export default Eposter;
